/* eslint-disable */
/*
 * This was automatically generated by frontend/onboarding/onboarding.ts.
 * DO NOT MANUALLY EDIT
 * Please refer to https://wistia.github.io/wistia/main/?path=/docs/onboarding-docs-getting-started--docs for more information.
 */

export const activeOnboarding = {
  createFromAnywhere: {
    id: 'createFromAnywhere-a0e6f3bf-d972-4caa-9bf1-3eb91e06c478',
    pillar: 'growth',
    product: 'onboarding'
  },
  audioDescriptionOrders: {
    id: 'audioDescriptionOrders-b8255e0f-4793-4969-9711-1f64de33dfd3',
    pillar: 'publish',
    product: 'customize'
  },
  customizePlayerGradients: {
    id: 'customizePlayerGradients-bbb26e21-8f7f-4ae6-9636-ffb981dffaf8',
    pillar: 'publish',
    product: 'customize'
  },
  'storybook-preview-hotspot': {
    id: 'storybook-preview-hotspot-4b1733d9-7f6c-448a-be7a-125e6dd6f3c4',
    pillar: 'growth',
    product: 'onboarding'
  },
  passwordChannelsShare: {
    id: 'passwordChannelsShare-ee36a26f-3331-49e8-8aff-e0b8f93e2ef6',
    pillar: 'publish',
    product: 'channel-page'
  },
  passwordChannelsCustomize: {
    id: 'passwordChannelsCustomize-e48084c4-7242-4a1d-967a-08bdb84ff2b3',
    pillar: 'publish',
    product: 'channel-page'
  }
};

export const SUPPORTED_FEATURE_IDS = [
  'createFromAnywhere',
  'audioDescriptionOrders',
  'customizePlayerGradients',
  'storybook-preview-hotspot',
  'passwordChannelsShare',
  'passwordChannelsCustomize'
] as const
