import { useFlags, useFlagsmith } from 'flagsmith/react';
import type { IFlagsmithFeature } from 'flagsmith/types';
import { useEffect, useState } from 'react';
import type { TupleToUnion } from 'type-fest';
import { useFlagsmithIdentityLoading } from '../providers/FeatureFlagProvider';

const useForceLoad = (): boolean => {
  const [forceLoad, setForceLoad] = useState<boolean>(false);
  const threeSeconds = 3000;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setForceLoad(true);
    }, threeSeconds);
    return () => {
      window.clearTimeout(timeout);
    };
  }, []);

  return forceLoad;
};

export const SUPPORTED_FEATURE_FLAG_KEYS = [
  'flag_allow_original_file_playback',
  'flag_allow_required_pardot_forms',
  'flag_analyze_heatmap_scrubber',
  'flag_anonymous_edit',
  'flag_app_redesign_2024',
  'flag_ask_lenny',
  'flag_attendee_heatmaps',
  'flag_attendee_heatmaps_chats',
  'flag_audience_links',
  'flag_aurora_embed_codes',
  'flag_aurora_media_page',
  'flag_brandfetch_wtw',
  'flag_brandfetch_wtw_v2',
  'flag_caption_customizations',
  'flag_channel_customize_updates',
  'flag_channel_thumbnails',
  'flag_checklist_for_all',
  'flag_clip_segments',
  'flag_customize_analytics',
  'flag_email_add_video_experiment',
  'flag_embed_options_timeline_items',
  'flag_enable_editor_react_scan_monitoring',
  'flag_social_clips_redesign',
  'flag_enable_live_react_scan_monitoring',
  'flag_enable_media_page_react_scan_monitoring',
  'flag_enable_record_react_scan_monitoring',
  'flag_extended_audio_descriptions',
  'flag_folder_page_info_panel',
  'flag_folder_permissions',
  'flag_ghost_channel',
  'flag_hackathon_live_event_colors',
  'flag_hackathon_webinar_intelligence',
  'flag_import_via_url',
  'flag_improved_download_modal',
  'flag_lead_gen_custom_checkbox_fields',
  'flag_link_folder_to_channel',
  'flag_live_attendee_list',
  'flag_live_chat_notifications',
  'flag_live_customize_updates',
  'flag_live_disallow_invalid_emails',
  'flag_live_emoji_reactions',
  'flag_live_event_account_overview',
  'flag_live_ics_attachment',
  'flag_live_linkedin_live',
  'flag_live_noise_cancellation',
  'flag_live_on_safari',
  'flag_live_pre_recorded_wistia_m3u8s',
  'flag_live_restartable',
  'flag_live_stage_informer',
  'flag_live_update_default_thumbnail_emails',
  'flag_live_wistia_player_conversion',
  'flag_media_localizations',
  'flag_my_state_of_video',
  'flag_network_connection_indicator',
  'flag_onboarding_embed_share_v2',
  'flag_paginate_customize_forms',
  'flag_paginate_live_forms',
  'flag_password_channels',
  'flag_playlist_gradient',
  'flag_pricing_trials_navigational_change',
  'flag_private_user_sessions_v2',
  'flag_recent_searches',
  'flag_record_preflight_redesign',
  'flag_record_recording_bot_backups',
  'flag_revamped_search',
  'flag_search',
  'flag_show_conversion_analytics',
  'flag_show_traffic_analytics',
  'flag_thumbnail_section_redux',
  'flag_transcript_translations',
  'flag_upcoming_invoice_account_settings',
  'flag_use_case_experiment',
  'flag_use_onboarding',
  'flag_use_paged_list_selector',
  'flag_use_searchable_list_select_channel_customize',
  'flag_use_searchable_list_select_for_media_form',
  'flag_vma_brand_refresh',
  'flag_webhooks_v2',
  'flag_wtw_add_video_experiment',
  'flag_use_vma_stage_state',
  'plans_page_redesign',
  'flag_editor_auto_edit',
] as const;

export type SupportedFeatureFlags = TupleToUnion<typeof SUPPORTED_FEATURE_FLAG_KEYS>;

export type UseLoadFeatureFlagsReturn<
  Flag extends SupportedFeatureFlags = Partial<SupportedFeatureFlags>,
> = {
  readonly loading: boolean;

  readonly flags: Record<Flag, IFlagsmithFeature>;
};

/**
 * V2 hook differs from v1 by checking if a flag has been loaded. Flags that are not loaded are returned as disabled by flagsmith by default.
 * Testing example can be seen here frontend/singlePageApp/hooks/useLoadFeatureFlagsV2.test.jsx
 */
export const useLoadFeatureFlags = <
  Flag extends SupportedFeatureFlags = Partial<SupportedFeatureFlags>,
  Trait extends string = '',
>(
  flags: Flag[],
  traits: Trait[] | undefined = undefined,
): UseLoadFeatureFlagsReturn<Flag> => {
  /**
   * If a user has never had the flag explicitly toggled on/off
   * OR flagsmith goes down, we need to force the loading state
   * after a few seconds to avoid the UI getting stuck in an infinite
   * loader.
   */
  const forceLoad = useForceLoad();
  const flagsmith = useFlagsmith();
  const result = useFlags(flags, traits);
  const flagsmithStateFlags = flagsmith.getState().flags;
  const flagsmithIdentityLoading = useFlagsmithIdentityLoading();

  const hasAllFlags =
    forceLoad ||
    flags.reduce((acc, flag) => {
      if (flagsmithStateFlags === undefined) {
        return false;
      }
      /**
       * Disabling because the TS compiler doesn't realize that we can provide a string that's not in the flags object.
       * Possible typing problem in Flagsmith
       */
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      return acc && !!flagsmithStateFlags[flag];
    }, true);

  return {
    loading: flagsmithIdentityLoading || !hasAllFlags,
    flags: result,
  };
};
